import { useContext, useEffect, useState } from 'react';
import { InputChangeEventDetail, IonButton, IonInput, IonRadio, IonRadioGroup } from '@ionic/react';
import './VatNumber.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _ from 'lodash';
import { Merchant, SetMerchantVatRatesAsync, SetVatNumberAsync, VatRate } from '../../lib';
import { IonInputCustomEvent } from '@ionic/core';
import { AccountContext } from '../controls/nav/AccountProvider';

type Props = {
    merchant: Merchant,
    refreshMerchant: Function,
};

export default function VatNumber(props: Props) {
    const merchant = props.merchant;
    const [isEditMode, setIsEditMode] = useState(false);
    const [vatNumber, setVatNumber] = useState(props.merchant.vatnumber);
    const [isVatValid, setIsVatValid] = useState(true);
    const [newVatRate, setNewVatRate] = useState<VatRate>({ rate: undefined, name: '', isDefault: false });
    const [settingVatRate, setSettingVatRate] = useState(false);
    const regExpVatNumber = new RegExp('^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$');
    const accountContext = useContext(AccountContext);

    async function onClickSaveVatNumber() {
        var valid = regExpVatNumber.test(vatNumber);
        setIsVatValid(valid);
        if (!valid) return;

        await SetVatNumberAsync(vatNumber);
        props.refreshMerchant();
        setIsEditMode(false);
    }

    function onClickCancel() {
        setIsEditMode(false);
        setVatNumber(merchant.vatnumber);
    }

    async function onClickSaveVatRate() {
        if (newVatRate.rate === undefined || newVatRate.name === '') return;
        merchant.vatRates = merchant.vatRates || [];
        merchant.vatRates.push(newVatRate);
        if (merchant.vatRates.length === 1) newVatRate.isDefault = true;

        setSettingVatRate(true);

        try {
            await SetMerchantVatRatesAsync(merchant.vatRates);

            setNewVatRate({ rate: undefined, name: '', isDefault: false });
            props.refreshMerchant();
        } finally {
            setSettingVatRate(false);
        }
    }

    function onVatRateChange(e: IonInputCustomEvent<InputChangeEventDetail>): void {
        var newRate = parseFloat(e.detail?.value || '');
        if (isNaN(newRate)) return;
        if (newRate < 0) newRate = newRate * -1;
        if (newRate > 100) newRate = 100;
        setNewVatRate(prev => ({ ...prev, rate: _.ceil(newRate, 2) }));
    }

    async function onDefaultVatRateChanged(index: number) {
        merchant.vatRates.forEach((rate, i) => {
            rate.isDefault = i === index;
        });

        await SetMerchantVatRatesAsync(merchant.vatRates);
        accountContext?.refresh();
    }

    function vatRatesText() {
        if (!merchant.vatRates || merchant.vatRates.length === 0) return "The VAT rate used on your payments will be the default rate for your region unless you provide alternatives below.";
        if (merchant.vatRates.length === 1) return `The VAT rate used on your payments will be the one shown below.`;
        return "Please choose the VAT rate that you would like to set as the default for your payments. You can change this on each payment if you wish.";
    }

    return <>
        <div className="panelTitle">
            <h2>VAT Number (If VAT Registered)</h2>
            <IonButton
                shape="round"
                color="secondary"
                size="small"
                hidden={isEditMode}
                className="settingsButtons" onClick={() => setIsEditMode(true)}>Edit &#187;</IonButton>
            <IonButton
                shape="round"
                color="primary"
                size="small"
                hidden={!isEditMode}
                className="settingsButtons" onClick={onClickSaveVatNumber}>Save &#187;</IonButton>
        </div>

        <div className="ion-margin-top ion-margin-bottom">
            <label>VAT Number</label>
            <IonInput color={"#A0A0A0"} placeholder={merchant?.vatnumber ? merchant.vatnumber : "GB123456789"}
                value={vatNumber}
                onIonChange={val => setVatNumber(val.detail.value!)}
                readonly={isEditMode === false}>
            </IonInput>
        </div>

        <div className='cancelVatButton'>
            <IonButton
                shape="round"
                color="secondary"
                size="small"
                hidden={!isEditMode}
                className="settingsButtons" onClick={onClickCancel}>Cancel &#187;</IonButton>
        </div>

        <div className='invalidVatNumber'><h5 hidden={isVatValid}>Invalid VAT Number</h5></div>

        <p>{vatRatesText()}</p>

        <div className="vatRates">
            <IonRadioGroup
                onIonChange={e => onDefaultVatRateChanged(e.detail.value - 1)}
                value={merchant.vatRates?.findIndex(rate => rate.isDefault) + 1}>
                {merchant.vatRates?.map((rate, index) => {
                    return <div style={{ display: "flex", alignItems: "center", marginBottom: "15px", fontSize: "14px" }} key={index + 1}>
                        <IonRadio value={index + 1} key={index + 1}></IonRadio>
                        &nbsp;
                        {rate.name} - {rate.rate}%
                        <br />
                    </div>
                })}
            </IonRadioGroup>
        </div>

        <label>New VAT Rate</label>
        <IonInput
            placeholder='New VAT Rate Name'
            value={newVatRate.name}
            onIonChange={val => setNewVatRate(prev => ({ ...prev, name: val.detail.value! }))}
            readonly={settingVatRate}>
        </IonInput>

        <div style={{ display: 'flex', marginTop: "5px" }}>
            <IonInput placeholder={"0-100"}
                type='number'
                value={newVatRate.rate != undefined ? newVatRate.rate : undefined}
                onIonChange={onVatRateChange}
                readonly={settingVatRate}>
            </IonInput>

            <IonButton
                shape="round"
                color="secondary"
                size="small"
                disabled={newVatRate.rate === undefined || newVatRate.name === '' || settingVatRate}
                className="settingsButtons" onClick={onClickSaveVatRate}>Save &#187;</IonButton>
        </div>

        <p style={{ fontSize: "12px" }}>It is your responsibility to ensure that the correct VAT rate is chosen.</p>
    </>
}
