import { useContext, useEffect, useState, useRef } from "react";
import { AccountContext } from "../controls/nav/AccountProvider";
import { ApiKey, GetApiKeysAsync, RecycleApiKeysAsync } from "../../lib";
import { IonButton, IonModal, IonIcon } from "@ionic/react";
import { copyOutline, eyeOutline, eyeOffOutline } from "ionicons/icons";

export default function ApiKeys() {
    const accountContext = useContext(AccountContext);
    const [apiKeys, setApiKeys] = useState<ApiKey | null>(null);
    const [showRegenDialog, setShowRegenDialog] = useState(false);
    const [copiedMessage, setCopiedMessage] = useState<string | null>(null);
    const lockApiCalls = useRef(false);
    const [showPublicKey, setShowPublicKey] = useState(false);
    const [showPrivateKey, setShowPrivateKey] = useState(false);

    useEffect(() => {
        if (!accountContext?.account) return;

        GetApiKeysAsync().then(apiKeys => {
            setApiKeys(apiKeys);
        });
    }, [accountContext?.account]);

    const handleCopy = (text: string, keyType: string) => {
        navigator.clipboard.writeText(text);
        setCopiedMessage(`${keyType} copied to clipboard`);
        setTimeout(() => setCopiedMessage(null), 2000);
    };

    const handleRegenerate = () => {
        if (lockApiCalls.current) return;
        lockApiCalls.current = true;

        try {
            RecycleApiKeysAsync().then(() => {
                setShowRegenDialog(false);
                accountContext?.refresh();
            });
        } finally {
            lockApiCalls.current = false;
        }
    };

    const obfuscateKey = (key: string) => {
        return key.split('').map((char, index) => {
            if (index < 4) return char;
            return /[^a-zA-Z0-9]/.test(char) ? char : '*';
        }).join('');
    };

    return <>        
        <div style={{ color: '#003466', marginBottom: '1rem' }}>
            <b>API Keys</b>
        </div>

        {!apiKeys && 
            <div>
                <p>There are no keys assigned to this account yet.</p>
                <div style={{ marginTop: '1rem' }}>
                    <IonButton 
                        fill="solid"
                        onClick={() => handleRegenerate()}
                    >
                        Generate Keys
                    </IonButton>
                </div>
            </div>
        }

        {apiKeys && <>
            <div style={{ marginBottom: '1rem' }}>
                <b style={{color:'#003466'}}>Client ID:</b> 
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input 
                        type="text"
                        value={showPublicKey ? apiKeys.id : obfuscateKey(apiKeys.id)}
                        disabled
                        style={{ 
                            flex: 1,
                            minWidth: 0,
                            padding: '0.5rem',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                        }}
                    />
                    <IonButton 
                        size="small"
                        style={{ width: '28px', minWidth: '28px' }}
                        onClick={() => setShowPublicKey(!showPublicKey)}
                    >
                        <IonIcon icon={showPublicKey ? eyeOffOutline : eyeOutline} />
                    </IonButton>
                    <IonButton 
                        size="small"
                        style={{ width: '28px', minWidth: '28px' }}
                        onClick={() => handleCopy(apiKeys.id, 'Client ID')}
                    >
                        <IonIcon icon={copyOutline} />
                    </IonButton>
                </div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <b style={{color:'#003466'}}>Client Secret:</b>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <input 
                        type="text"
                        value={showPrivateKey ? apiKeys.key : obfuscateKey(apiKeys.key)}
                        disabled
                        style={{ 
                            flex: 1,
                            minWidth: 0,
                            padding: '0.5rem',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                        }}
                    />
                    <IonButton
                        size="small"
                        style={{ width: '28px', minWidth: '28px' }}
                        onClick={() => setShowPrivateKey(!showPrivateKey)}
                    >
                        <IonIcon icon={showPrivateKey ? eyeOffOutline : eyeOutline} />
                    </IonButton>
                    <IonButton
                        size="small"
                        style={{ width: '28px', minWidth: '28px' }}
                        onClick={() => handleCopy(apiKeys.key, 'Client Secret')}
                    >
                        <IonIcon icon={copyOutline} />
                    </IonButton>
                </div>
            </div>
            
            <div style={{ marginTop: '1rem' }}>
                <IonButton 
                    fill="solid"
                    onClick={() => setShowRegenDialog(true)}
                >
                    Regenerate Keys
                </IonButton>
            </div>

            {copiedMessage && 
                <div style={{ marginTop: '1rem', color: 'green' }}>
                    {copiedMessage}
                </div>
            }

            <IonModal
                isOpen={showRegenDialog}
                onDidDismiss={() => setShowRegenDialog(false)}
            >
                <div style={{ padding: '1.5rem' }}>
                    <h3 style={{color:'#003466', fontWeight:'bold'}}>Confirm</h3>
                    <p>Are you sure you want to regenerate API keys? The existing keys will stop working.</p>
                    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
                        <IonButton onClick={() => setShowRegenDialog(false)}>No</IonButton>
                        <IonButton fill="solid" onClick={handleRegenerate}>Yes</IonButton>
                    </div>
                </div>
            </IonModal>
        </>}
        
        <div style={{ fontSize: '0.9rem', color: '#666', marginBottom: '1rem', marginTop: '1rem' }}>
            To find out how to utilize these keys, please visit&nbsp;
            <a href="https://www.trustisttransfer.com/docs" target="_blank">https://www.trustisttransfer.com/docs</a>
        </div>
    </>;
}
