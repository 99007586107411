import { IonCheckbox, IonCol, IonContent, IonGrid, IonImg, IonInput, IonPage, IonRow, IonText } from "@ionic/react";
import { GettingStartedQuestions, Onboarding, OrgTypes, OrgTypesDisplayText, SetGettingStartedQuestions } from "../../../lib";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import ItemListControl from "../../controls/shared/ItemListControl";
import { useState } from "react";
import { CheckBoxRow } from "../../controls/shared/Checkbox";
import { SubmitButton } from "../../controls/shared/Buttons";

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function GettingStartedStep(props: Props) {
    const [questions, setQuestions] = useState<GettingStartedQuestions>({});
    const [isChecked, setIsChecked] = useState(false);

    function isFormComplete() {
        return questions.orgType && isChecked === true &&
            (questions.orgType !== OrgTypes.Other || questions.orgTypeOther);
    }

    async function onClickComplete() {
        await SetGettingStartedQuestions(questions);
        props.onNext();
    }

    const disabledOrgTypes = ['charity', 'trust', 'foundation', 'publicSectorOrganisation', 'other']; // Add other org types you want to disable here

    const form = <>
        <IonPage>
            <IonContent>
                <IonGrid>

                    <RowWithDynamicGutter>
                        <IonImg className="smallLogo" src='./assets/img/logo.png' />
                    </RowWithDynamicGutter>

                    <RowWithDynamicGutter>
                        <div className="sectionTitle">
                            <b>Business Type</b>
                        </div>

                        <p className="onboardingSubText">Please select the legal type of your business</p>

                        <ItemListControl
                            items={Array.from(OrgTypesDisplayText, ([key, value]) => ({ key, value }))}
                            onItemSelected={(item) => setQuestions({ ...questions, orgType: item })}
                            disabledItems={disabledOrgTypes} // Pass the disabledOrgTypes array here
                        ></ItemListControl>

                        <div hidden={questions.orgType != OrgTypes.Other}>
                            <h5 className="inputLabel"><b>Please enter your organisation type below</b></h5>

                            <IonInput
                                className='input'
                                type="text"
                                value={questions.orgTypeOther}
                                onIonChange={(val) => setQuestions({ ...questions, orgType: val.detail.value || undefined })}>
                            </IonInput>
                        </div>
                    </RowWithDynamicGutter>
                    <div hidden={questions.orgType == OrgTypes.CIO}>
                    <RowWithDynamicGutter>
                        <div className="sectionTitle ion-margin-top">
                            <b>Which Apply?</b>
                        </div>
                    
                        <p className="onboardingSubText" style={{ marginBottom: '0px' }}>Do any of these apply to your business?</p>
                    </RowWithDynamicGutter>
                    
                    
                    <CheckBoxRow label="You have 50+ Employees"
                        checked={questions.overFiftyEmployees || false}
                        onChange={checked => setQuestions({ ...questions, overFiftyEmployees: checked })} />

                    <CheckBoxRow label="The equity on our balance sheet (the last line) exceeds £5M"
                        checked={questions.overFiveMillionBalance || false}
                        onChange={checked => setQuestions({ ...questions, overFiveMillionBalance: checked })} />

                    <CheckBoxRow label="Annual turnover is greater than £10M"
                        checked={questions.overTenMillionTurnover || false}
                        onChange={checked => setQuestions({ ...questions, overTenMillionTurnover: checked })} />
                    </div> 
                    <RowWithDynamicGutter>
                        <IonRow style={{ marginTop: "25px", borderTop: "1px solid #d8d8d8" }}>
                            <IonCol style={{ paddingLeft: '0px', alignSelf: 'center', marginTop: '5px' }} sizeXs='1' sizeSm='1' sizeMd='1' sizeLg='1' sizeXl='1'>
                                <IonCheckbox checked={isChecked} onIonChange={e => setIsChecked(e.detail.checked)}></IonCheckbox>
                            </IonCol>
                            <IonCol sizeXs='11' sizeSm='11' sizeMd='11' sizeLg='11' sizeXl='11'>
                                <IonText color={"primary"}>
                                    <h6 style={{ fontWeight: 500, fontSize: "10px", alignSelf: 'center' }}>
                                        I confirm that I have read and agree to the <a href='https://trustisttransfer.com/terms-and-conditions/' target="_blank" rel="noreferrer">terms and conditions</a>.
                                    </h6>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </RowWithDynamicGutter>

                    <RowWithDynamicGutter>

                        <SubmitButton
                            class="ion-margin-top"
                            onClick={onClickComplete}
                            text="Complete &#187;"
                            disabled={!isFormComplete()} />

                    </RowWithDynamicGutter>

                </IonGrid>
            </IonContent>
        </IonPage>
    </>;

    return form;
}