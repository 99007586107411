import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from "@ionic/react";
import { useState } from "react";
import { Beneficiary, Onboarding, NominateApplicant, KycMode, Director } from "../../../lib";
import { SubmitButton } from "../../controls/shared/Buttons";
import "./NominateApplicant.css";
import ItemListControl from "../../controls/shared/ItemListControl";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function NominateApplicantStep(props: Props) {
    const [selectedOfficer, setSelectedOfficer] = useState<Director | null>(null);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState<Beneficiary | null>(null);

    async function HandleNotOnList() {
        var result = await NominateApplicant(); // no beneficiary = not on list
        if (result === true) props.onNext();
    }

    async function SetApplicant(e: any) {
        var result = await NominateApplicant(selectedBeneficiary!,selectedOfficer!);
        if (result === true) props.onNext();
    }


    function handleOfficerSelected(selectedValue: string) {
        const officer = props.onboarding.businessDetails.directors.find(
            (director: Director) => director.id === selectedValue,
        );
        setSelectedOfficer(officer || null);
    }

    function handleBeneficiarySelected(selectedValue: string) {
        const beneficiary = props.onboarding.businessDetails.beneficiaries.find(
            (beneficiary: Beneficiary) => beneficiary.id === selectedValue,
        );
        setSelectedBeneficiary(beneficiary || null);
    }

 

    return <>
    <IonPage>
        <IonContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo" src='./assets/img/logo.png' />
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <div className="sectionTitle">
                        <b>{props.onboarding.kycMode === KycMode.BusinessBeneficiaries ? "Persons of Significant Control" : "Directors"}</b>
                    </div>
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <p className='onboardingSubText'>Please select your name from the list below, if you are not a {props.onboarding.kycMode === KycMode.BusinessBeneficiaries ? "person of significant control" : "director"} select “I’m not on This List”.</p>
                </RowWithDynamicGutter>
                <div className="scrollable-content">
                    <RowWithDynamicGutter>

                        {props.onboarding.kycMode === KycMode.BusinessBeneficiaries &&
                        <ItemListControl
                            items={props.onboarding.businessDetails.beneficiaries ? props.onboarding.businessDetails.beneficiaries.map((beneficiary: Beneficiary) => ({
                                key: beneficiary.id,
                                value: `${beneficiary.firstName} ${beneficiary.lastName}`,
                            })) : []}
                            onItemSelected={handleBeneficiarySelected}
                        />}

                        {props.onboarding.kycMode === KycMode.BusinessDirector &&
                        <ItemListControl
                            items={props.onboarding.businessDetails.directors ? props.onboarding.businessDetails.directors.map((director: Director) => ({
                                key: director.id,
                                value: `${director.name}`,
                            })) : []}
                            onItemSelected={handleOfficerSelected}
                        />}
                    </RowWithDynamicGutter>
                </div>
                <div style={{ marginTop: "-10px" }}>
                    <RowWithDynamicGutter>
                        <SubmitButton
                            disabled={(selectedOfficer || selectedBeneficiary) === null}
                            text="Confirm »"
                            onClick={SetApplicant} />                     
                        <IonButton
                            fill="clear" class="notRegisteredButton"
                            onClick={HandleNotOnList}>I’m not on This List »</IonButton>
                    </RowWithDynamicGutter>
                </div>
            </IonGrid >
        </IonContent>
    </IonPage>
        <div className='paddingDiv'></div>
    </>;
}